import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import ENV from "./env-config";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: "en-GB",
    returnObjects: true,

    ns: ["translations"],
    defaultNS: "translations",

    debug: false,

    interpolation: {
      escapeValue: false,
    },

    language: "en-GB",
    load: "currentOnly",

    backend: {
      loadPath: function(lng) {
        return ENV.apiBase + "/translations/list_by_language_id?language=" + lng;
      },
      crossDomain: true,
    },

    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
