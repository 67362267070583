import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-regular-svg-icons";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";

import { ConditionIcon, GalleryButton, Expandable } from "../../../../../components";
import { Responsive, DesktopOnly, MobileOnly } from "../../../../../layout";
import { round2, getItemName } from "../../../../../util/common";
import { getTyreSeasonIcon } from "../../../../../util/tyre";

import "./index.scss";

class FixedItems extends Component {
  renderRow = (item, id, description, price, images, videos, status, profile) => {
    let rep = null;
    if (item.tyre_position > 0 && item.tyre_replacements.length > 0) rep = item.tyre_replacements.find(r => r.mechanic_fixed);

    return (
      <Grid.Row key={id} className="fixed-row">
        <Grid.Column width={1} className="fixed-status-icon">
          <ConditionIcon status={status} size="medium" />
        </Grid.Column>
        <Grid.Column computer={5} widescreen={5} mobile={14} className="fixed-items-status">
          <Expandable className="fixed-title" text={getItemName(this.props.t, item)} />
        </Grid.Column>
        <Grid.Column computer={6} mobile={16} className="fixed-description">
          {profile && <Expandable text={profile + "mm"} />}

          <Expandable text={description} />
        </Grid.Column>
        <MobileOnly>
          <Grid.Column width={16}></Grid.Column>
          <Grid.Column width={6}>{price}</Grid.Column>
        </MobileOnly>
        <DesktopOnly>
          <Grid.Column textAlign="center" width={3} className={`${status === -1 ? "no-status-icon" : ""}`}>
            {price}
          </Grid.Column>
          {rep && (
            <>
              <Grid.Column width={5} className="fixed-replaced">
                {this.props.t("cc_replaced_with").message || "Replaced with"}: {getTyreSeasonIcon(rep.tyre.season)}
                {rep.tyre.manufacturer}
                <div>{rep.tyre.description}</div>
              </Grid.Column>
              <Grid.Column width={5} className="fixed-description">
                <Expandable text={rep.remarks} />
              </Grid.Column>
            </>
          )}
        </DesktopOnly>
        <MobileOnly>
          <Grid.Column mobile={7} textAlign="right" className="fixed-status-container">
            <ConditionIcon status={status} size="medium" />
            {status > -1 && <FontAwesomeIcon className="fixed-arrow" icon={faLongArrowRight} />}
            <ConditionIcon status={0} size="medium" />
          </Grid.Column>
        </MobileOnly>
        <Grid.Column computer={1} mobile={3}>
          <GalleryButton images={images} videos={videos} />
        </Grid.Column>
      </Grid.Row>
    );
  };

  renderPrice = price => {
    const { include_vat, vat } = this.props.settings;

    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="fixed-price">€ {round2(price).toFixed(2)}</span>;
  };

  renderFixedItems = interventions => {
    if (!Array.isArray(interventions)) return null;

    const { appointment } = this.props.communication;
    const hasImages = Array.isArray(appointment.final_check_images);
    const hasVideos = Array.isArray(appointment.final_check_videos);

    return interventions.map(i =>
      this.renderRow(
        i,
        i.id,
        i.description,
        this.renderPrice(i.price),
        hasImages && appointment.final_check_images.filter(img => img.intervention_index === i.intervention_index),
        hasVideos && appointment.final_check_videos.filter(video => video.intervention_index === i.intervention_index),
        -1
      )
    );
  };

  render() {
    let { fixedItems, showMobile, t } = this.props;

    if (fixedItems.length < 1) return null;

    return (
      <Responsive showMobile={showMobile}>
        <Grid verticalAlign="middle" className="repair-overview-v2 fixed-container component-container">
          <Grid.Row className="fixed-heading">
            <Grid.Column width={16}>
              <FontAwesomeIcon icon={faCog} className="fixed-heading-icon" />
              <h4 className="fixed-heading-title">{t("cc_items_fixed").message || "Items fixed"}</h4>
            </Grid.Column>
          </Grid.Row>
          {this.renderFixedItems(fixedItems)}
        </Grid>
      </Responsive>
    );
  }
}

const TranslatedFixedItems = withTranslation()(FixedItems);

TranslatedFixedItems.filterFixedItems = results => results.filter(r => r.mechanic_fixed);

export default TranslatedFixedItems;
