import React from "react";
import { withRouter } from "react-router-dom";
import { Image, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUserHeadset } from "@fortawesome/pro-solid-svg-icons";

import { DesktopOnly, MobileOnly } from "../../../../layout/";

import "./index.scss";

const Menu = ({ children, settings, supportActive, goToSupportScreen, goBackFromSupportScreen }) => {
  let { t } = useTranslation();

  return (
    <>
      <DesktopOnly className="side-menu-container">
        <div className="location-logo">
          <Image centered size="small" src={settings.logo} />
        </div>
        <div className="menu">
          <Button onClick={goBackFromSupportScreen} type="button" icon className={"item " + (supportActive ? "" : " bg-color")}>
            <FontAwesomeIcon icon={faCog} />
            {t("cc_Items to fix").message || "Items to fix"}
          </Button>

          {settings.support_enabled && (
            <Button onClick={goToSupportScreen} type="button" icon className={"item " + (supportActive ? " bg-color" : "")}>
              <FontAwesomeIcon icon={faUserHeadset} />
              {t("cc_Support").message || "Support"}
            </Button>
          )}
        </div>
        {children}
      </DesktopOnly>
      <MobileOnly>{children}</MobileOnly>
    </>
  );
};

export default withRouter(Menu);
