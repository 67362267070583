import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Keyboard from "react-simple-keyboard";

import "react-simple-keyboard/build/css/index.css";

import "./index.scss";

const Backspace_Icon =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="backspace" class="svg-inline--fa fa-backspace fa-w-20 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M576 64H205.26A63.97 63.97 0 0 0 160 82.75L9.37 233.37c-12.5 12.5-12.5 32.76 0 45.25L160 429.25c12 12 28.28 18.75 45.25 18.75H576c35.35 0 64-28.65 64-64V128c0-35.35-28.65-64-64-64zm-84.69 254.06c6.25 6.25 6.25 16.38 0 22.63l-22.62 22.62c-6.25 6.25-16.38 6.25-22.63 0L384 301.25l-62.06 62.06c-6.25 6.25-16.38 6.25-22.63 0l-22.62-22.62c-6.25-6.25-6.25-16.38 0-22.63L338.75 256l-62.06-62.06c-6.25-6.25-6.25-16.38 0-22.63l22.62-22.62c6.25-6.25 16.38-6.25 22.63 0L384 210.75l62.06-62.06c6.25-6.25 16.38-6.25 22.63 0l22.62 22.62c6.25 6.25 6.25 16.38 0 22.63L429.25 256l62.06 62.06z"></path></svg>';
const Shift_Icon =
  '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-alt-up" class="svg-inline--fa fa-arrow-alt-up fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M272 480h-96c-13.3 0-24-10.7-24-24V256H48.2c-21.4 0-32.1-25.8-17-41L207 39c9.4-9.4 24.6-9.4 34 0l175.8 176c15.1 15.1 4.4 41-17 41H296v200c0 13.3-10.7 24-24 24z"></path></svg>';

const Theme = "hg-theme-default hg-theme-ios";

const Layouts = [
  {
    default: ["q w e r t y u i o p {bksp}", "a s d f g h j k l {enter}", "{shift} z x c v b n m , . {shift}", "{alt} {space} {alt}"],
    shift: ["Q W E R T Y U I O P {bksp}", "A S D F G H J K L {enter}", "{shiftactivated} Z X C V B N M , . {shiftactivated}", "{alt} {space} {alt}"],
    alt: ["1 2 3 4 5 6 7 8 9 0 {bksp}", `@ # $ & * ( ) ' " {enter}`, "% - + = / ; : ! ?", "{altactivated} {space} {altactivated}"],
  },
  { default: ["1 2 3 4 5 6 7 8 9 0", "Q W E R T Y U I O P", "A S D F G H J K L", "Z X C V B N M {bksp}"] },
  {
    default: ["q w e r t y u i o p {bksp}", "a s d f g h j k l {enter}", "{shift} z x c v b n m , .", "{space}"],
    shift: ["Q W E R T Y U I O P {bksp}", "A S D F G H J K L {enter}", "{shiftactivated} Z X C V B N M , .", "{space}"],
  },
];

export const LAYOUTS = {
  FULL: 0,
  REGISTRATION: 1,
  SIGNATURE: 2,
};

class VirtualKeyboard extends Component {
  state = { layoutName: this.props.text || this.props.layout === LAYOUTS.REGISTRATION ? "default" : "shift" };

  toggleShift = () => this.setState({ layoutName: this.state.layoutName === "shift" ? "default" : "shift" });

  toggleAlt = () => this.setState({ layoutName: this.state.layoutName === "alt" ? "default" : "alt" });

  onKeyPress = button => {
    if (button === "{shift}" || button === "{shiftactivated}") this.toggleShift();
    else if (button === "{alt}" || button === "{altactivated}") this.toggleAlt();
    else if (this.state.layoutName !== "default" && !(button >= 0 && button <= 9)) this.setState({ layoutName: "default" });

    if (this.props.onKeyPress) this.props.onKeyPress(button);
  };

  onInit = keyboard => {
    this.keyboard = keyboard;
    this.keyboard.setInput(this.props.text);
  };

  render() {
    const { layoutName } = this.state;
    const { t, layout } = this.props;

    const display = {
      "{alt}": ".?123",
      "{shift}": Shift_Icon,
      "{shiftactivated}": Shift_Icon,
      "{enter}": layout === LAYOUTS.SIGNATURE ? t("cc_done").message || "Done" : t("kl_enter").message || "Enter",
      "{bksp}": Backspace_Icon,
      "{space}": t("kl_space").message || "Space",
      "{altactivated}": "ABC",
    };

    return (
      <Keyboard
        theme={Theme}
        layout={Layouts[layout]}
        layoutName={layoutName}
        display={display}
        onChange={this.props.onChange}
        onKeyPress={this.onKeyPress}
        newLineOnEnter={true}
        disableCaretPositioning={true}
        onInit={this.onInit}
      />
    );
  }
}

export default withTranslation()(VirtualKeyboard);
