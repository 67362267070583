import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faExclamationCircle, faTimes, faUserCheck, faThumbsUp } from "@fortawesome/pro-light-svg-icons";

import "./index.scss";

const CONDITION_ANSWER_STATUSES = [
  { value: 0, color: "green", icon: faUserCheck },
  { value: 1, color: "green", icon: faUserCheck },
  { value: 2, color: "orange", icon: faExclamationTriangle },
  { value: 3, color: "red", icon: faExclamationCircle },
  { value: 4, color: "gray", icon: faTimes },
  { value: -1, color: "gray" },
  { value: 10, color: "light-green", icon: faThumbsUp },
];

class ConditionIcon extends Component {
  static CRITICAL = 3;

  static getConditionColor(status) {
    const condition = CONDITION_ANSWER_STATUSES.find(c => c.value === status);
    return condition ? condition.color : "";
  }

  render() {
    let { status, size } = this.props;

    if (status == undefined) return null;

    const condition = CONDITION_ANSWER_STATUSES.find(c => c.value === status);
    if (!condition || !condition.icon) return null;

    let classes = "condition-icon " + condition.color;
    if (size) classes += " " + size;

    return (
      <div className={classes}>
        <FontAwesomeIcon icon={condition.icon} color="white" />
      </div>
    );
  }
}

export default ConditionIcon;
