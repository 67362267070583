import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";

import { ImageLetters } from "../";
import { ConditionIcon } from "../../../../components";
import { getTyreSeasonIcon, TYRE_POSITION } from "../../../../util/tyre";
import { getItemName, QUESTION_RESULT_STATUS, round2 } from "../../../../util/common";

import "./index.scss";

class NotFixedCheckItems extends Component {
  renderRow = (item, id, description, price, images, status, isIntervention) => {
    const { t } = this.props;

    let rep = null;
    if (TYRE_POSITION.IS_TYRE(item.tyre_position) && item.tyre_replacements.length > 0) rep = item.tyre_replacements;

    return (
      <Grid.Row key={id} className="not-fixed-row">
        <Grid.Column width={13}>
          <ImageLetters style={{ marginRight: "7px" }} images={images} lettersMap={this.props.lettersMap} />
          {item.title && <span className="not-fixed-checks-title">{getItemName(this.props.t, item)}</span>}
          {TYRE_POSITION.IS_TYRE(item.tyre_position) && (
            <>
              {getTyreSeasonIcon(item.tyre.season, "#578091")}
              <span className="not-fixed-checks-tyre-manufacturer">{item.tyre.manufacturer}</span>
              <span className="not-fixed-checks-tyre-description">{item.tyre.description}</span>
              <span className="not-fixed-checks-profile">{item.tyre_profile}</span>
            </>
          )}
          {description && (
            <div className="not-fixed-checks-profile-desc-container">
              <span className="not-fixed-checks-description">{description}</span>
            </div>
          )}
        </Grid.Column>
        <Grid.Column width={2} className="not-fixed-checks-price" textAlign="center">
          {!rep && price}
        </Grid.Column>
        {!isIntervention && (
          <Grid.Column width={1} className="not-fixed-checks-status">
            <ConditionIcon status={status || 3} size="small" />
          </Grid.Column>
        )}
        {rep &&
          rep.map(r => (
            <React.Fragment key={r.tyre_id}>
              <Grid.Column className="not-fixed-row" width={13}>
                <span className="not-fixed-checks-replacement-title">
                  <span className="tyre-description">{t("Replacement").message || "Replacement"}</span> {getTyreSeasonIcon(r.tyre.season, "#578091")}
                </span>
                {r.tyre.description && (
                  <>
                    <span className="not-fixed-checks-tyre-manufacturer">{r.tyre.manufacturer}</span>
                    <span className="not-fixed-checks-tyre-description">{r.tyre.description}</span>
                  </>
                )}
                {r.remark && <div className="tyre-remark">{r.remark}</div>}
              </Grid.Column>
              <Grid.Column textAlign="center" width={2}>
                {this.renderPrice(r.price)}
              </Grid.Column>
            </React.Fragment>
          ))}
      </Grid.Row>
    );
  };

  renderPrice = price => {
    const { include_vat, vat } = this.props.settings;

    if (!(price > 0.01)) return null;

    if (include_vat) price = price * (1 + vat / 100);

    return <span className="not-fixed-price">€ {round2(price).toFixed(2)}</span>;
  };

  renderInterventions = (interventions, customerOk) => {
    if (!Array.isArray(interventions)) return null;

    const { appointment } = this.props.communication;
    const hasImages = Array.isArray(appointment.final_check_images);

    return interventions
      .filter(i => i.customer_ok === customerOk)
      .map(i =>
        this.renderRow(
          i,
          i.id,
          i.description,
          this.renderPrice(i.price),
          hasImages && appointment.final_check_images.filter(img => img.intervention_index === i.intervention_index),
          false,
          true
        )
      );
  };

  renderNotFixedItems = (notFixedItem, customerApproved) => {
    if (!Array.isArray(notFixedItem)) return null;

    return notFixedItem
      .filter(nfi => nfi.question_status > -1 && nfi.customer_approved === customerApproved)
      .map(nfi =>
        this.renderRow(nfi, nfi.question_result_id + "_" + nfi.tyre_position, nfi.mechanic_notes, this.renderPrice(nfi.price), nfi.images, nfi.question_status, false)
      );
  };

  render() {
    const { communication, notFixedItems, t } = this.props;
    let { appointment } = communication;
    let interventions = appointment.interventions.filter(i => !i.mechanic_fixed);

    const customerApprovedNotFixedItems = this.renderNotFixedItems(notFixedItems, true);
    const customerNotApprovedNotFixedItems = this.renderNotFixedItems(notFixedItems, false);

    const customerOkInterventions = this.renderInterventions(interventions, true);
    const customerNotOkInterventions = this.renderInterventions(interventions, false);

    if (interventions.length < 1 && notFixedItems.length < 1) return null;

    return (
      <Grid verticalAlign="middle" className="report-not-fixed-check-container component-container">
        <Grid.Row className="not-fixed-heading">
          <Grid.Column width={16}>
            <div className="not-fixed-heading-title-container">
              <div className="not-fixed-heading-title-sub-container">
                <FontAwesomeIcon icon={faTimesCircle} className="not-fixed-heading-icon" />
                <h2 className="not-fixed-heading-title">{t("cc_items_not_fixed").message || "Items not fixed"}</h2>
              </div>

              <div className="not-fixed-legend-container">
                <div className="not-fixed-heading-sub-status-item">
                  <ConditionIcon status={2} size="small" />
                  <span className="not-fixed-heading-sub-status-text">{t("cc_advisable").message || "Advisable"}</span>
                </div>
                <div className="not-fixed-heading-sub-status-item">
                  <ConditionIcon status={3} size="small" />
                  <span className="not-fixed-heading-sub-status-text">{t("cc_necessary").message || "Necessary"}</span>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>

        {(customerApprovedNotFixedItems.length > 0 || customerOkInterventions.length > 0) && (
          <>
            <Grid.Row className="not-fixed-heading-sub">
              <Grid.Column width={16}>
                <div className="not-fixed-heading-sub-text">
                  <h2>{t("cc_customer_approved").message || "Customer approved"}</h2>
                  {t("cc_not_fixed_will_be_repaired").message || "In agreement with you, items below will be repaired."}
                </div>
              </Grid.Column>
            </Grid.Row>
            {customerOkInterventions}
            {customerApprovedNotFixedItems}
          </>
        )}

        {(customerNotApprovedNotFixedItems.length > 0 || customerNotOkInterventions.length > 0) && (
          <>
            <Grid.Row className="not-fixed-heading-sub">
              <Grid.Column width={16}>
                <div className="not-fixed-heading-sub-text">
                  <h2>{t("cc_customer_not_approved").message || "Customer not approved"}</h2>
                  {t("cc_items_not_fixed_subtitle").message || "In agreement with you, the items below will not be repaired. Feel free to contact us."}
                </div>
              </Grid.Column>
            </Grid.Row>
            {customerNotOkInterventions}
            {customerNotApprovedNotFixedItems}
          </>
        )}
      </Grid>
    );
  }
}

const TranslatedNotFixedCheckItems = withTranslation()(NotFixedCheckItems);

TranslatedNotFixedCheckItems.filterNotFixedItems = results => {
  return results.filter(r => {
    if (TYRE_POSITION.IS_TYRE(r.tyre_position) && r.tyre_replacements.length > 0) {
      if (!r.tyre_replacements.some(tr => tr.mechanic_fixed)) {
        r.customer_approved = r.tyre_replacements.some(tr => tr.customer_answer);

        if (r.tyre_replacements.length === 1) r.price = r.tyre_replacements[0].price;
        else r.price = -1;

        return true;
      } else {
        if (r.question_status !== QUESTION_RESULT_STATUS.ADVISED || r.question_status !== QUESTION_RESULT_STATUS.NECESSARY || r.mechanic_fixed) return false;

        return true;
      }
    } else {
      return (r.question_status === QUESTION_RESULT_STATUS.ADVISED || r.question_status === QUESTION_RESULT_STATUS.NECESSARY) && !r.mechanic_fixed;
    }
  });
};

export default TranslatedNotFixedCheckItems;
